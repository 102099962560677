<template>
  <PVSidebar
    v-model:visible="visible"
    class="!w-[30%]"
    :header="$t('course_page.files_library')">
    <div class="flex flex-col h-full gap-3 px-5 py-4">
      <SearchInput
        v-model="search"
        class=""
        :placeholder="$t('course_page.search_file')"
        :style="'neutral'"
        full
        @reset-search="search = ''"/>
      <label v-if="search" class="!text-secondary-975 text-xs">
        {{ `${$t('course_page.results_for')} "${search}"` }}
      </label>
      <div
        v-if="filteredLinks.length || introduction"
        class="flex flex-1 h-full">
        <ScrollableContainer>
          <div class="px-1 py-1.5">
            <div
              class="flex justify-between items-center shadow-xs py-3 px-4 flex-1 rounded-md"
              @click="previewVideoDialogVisible = true">
              <p class="text-secondary-900 text-sm font-simplerBold">
                {{ $t('course_page.preview_video') }}
              </p>
              <div
                class="flex p-1.5 rounded-full items-center justify-center bg-[#F4F7FB] hover:!bg-[#EAEEF6] cursor-pointer active:!bg-[#E0F2FE]">
                <Play :size="13" />
              </div>
            </div>
          </div>
          <div
            v-for="(link, index) in filteredLinks"
            :key="index"
            class="px-1 py-1.5">
            <a :href="link.url" :target="link.target">
              <div
                class="flex justify-between items-center shadow-xs py-3 px-4 flex-1 rounded-md">
                <p class="text-secondary-900 text-sm font-simplerBold">
                  {{ link.title.replace('>', '') }}
                </p>
                <div
                  class="flex p-1.5 rounded-full items-center justify-center bg-[#F4F7FB] hover:!bg-[#EAEEF6] cursor-pointer active:!bg-[#E0F2FE]">
                  <Download :size="13" />
                </div>
              </div>
            </a>
          </div>
        </ScrollableContainer>
      </div>
      <div
        v-else
        class="w-full flex flex-1 flex-col justify-center items-center gap-2.5 p-3">
        <ConstructionCone />
        <text_xs>{{ $t('course_page.no_results') }}</text_xs>
      </div>
    </div>
  </PVSidebar>
  <PVDialog
    v-model:visible="previewVideoDialogVisible"
    modal
    :style="{ width: '40rem' }">
    <template #header>
      <label
        class="text-secondary-800 text-sm font-simplerRegular font-semibold">
        {{ $t('course_page.preview_video') }}
      </label>
    </template>
    <div class="flex">
      <video autoplay controls class="flex w-full items-center justify-center">
        <source :src="introduction" type="video/mp4" />
      </video>
    </div>
  </PVDialog>
</template>

<script setup lang="ts">
import PVSidebar from 'primevue/sidebar';
import PVDialog from 'primevue/dialog';
import { Download, Play } from 'lucide-vue-next';
import { SearchInput } from '@amit/foundation';
import { computed, ref } from 'vue';
import ScrollableContainer from '/@/views/playground/partials/ScrollableContainer.vue';
import ConstructionCone from '/@/assets/construction-cone.svg?component';

const props = defineProps({
  links: {
    type: Array,
    required: true,
  },
  introduction: {
    type: String,
    default: null,
  },
});

const visible = defineModel<boolean | undefined>();
const search = ref('');
const previewVideoDialogVisible = ref(false);

const filteredLinks = computed(() =>
  props.links?.filter(
    link =>
      link.title?.toLowerCase().includes(search.value.toLowerCase()) ||
      search.value === '',
  ),
);
</script>
